import * as React from 'react';

import { ReactComponent as SearchSymbolSvg } from '../../assets/SearchSymbol.svg';
import { OnSuggestionsFooterClickHandler } from '../../../corvid/searchBoxValuePropsSDKFactory';

import style from './Footer.scss';

type FooterProps = {
  onSuggestionsFooterClick: OnSuggestionsFooterClickHandler;
  footerText: string;
};

export const Footer: React.FC<FooterProps> = ({
  onSuggestionsFooterClick,
  footerText,
}) => {
  return (
    <button
      className={style.root}
      role="link"
      data-testid="footer-link"
      onClick={onSuggestionsFooterClick}
    >
      <SearchSymbolSvg className={style.icon} />
      <span className={style.text}>{footerText}</span>
    </button>
  );
};
