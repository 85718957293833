// TODO: Rename this component to Overlay.tsx to better reflect its responsibilities
import * as React from 'react';
import classNames from 'classnames';

import {
  SearchSuggestionGroup,
  SearchSuggestionItem,
} from '@wix/thunderbolt-components';

import * as translations from '../i18n.constants';
import { Footer } from './footer/Footer';
import { Group } from './group/Group';
import { Item } from './item/Item';
import {
  SuggestionRenderItemProps,
  SuggestionsProps,
} from './Suggestion.types';

import style from './Suggestions.scss';

export const Suggestions: React.FC<SuggestionsProps> = props => {
  const {
    children,
    onSuggestionsFooterClick,
    query,
    scrollIntoRef,
    shouldShowSuggestions,
    suggestions,
    translate,
  } = props;

  const getFooterText = () => {
    return `${translate(
      translations.NAMESPACE,
      translations.SUGGESTION_FOOTER_SEARCH_ALL_KEY,
      translations.SUGGESTION_FOOTER_SEARCH_ALL_DEFAULT,
    )} "${query}"`;
  };

  const isSuggestionsShown = Boolean(shouldShowSuggestions && suggestions);

  const renderItem = ({
    action,
    id,
    isActive,
    item,
    type,
  }: SuggestionRenderItemProps) => {
    return (
      <div
        aria-selected={isActive}
        className={style.suggestionItem}
        data-testid={id}
        id={id}
        key={id}
        ref={isActive ? scrollIntoRef : undefined}
        role="option"
      >
        {type === 'item' ? (
          <Item
            action={action}
            id={id}
            item={item as SearchSuggestionItem}
            translate={translate}
          />
        ) : (
          <Group
            action={action}
            id={id}
            item={item as SearchSuggestionGroup}
            translate={translate}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames(style.overlay, {
        [style.suggestionsShown]: isSuggestionsShown,
        [style.suggestionsHidden]: !isSuggestionsShown,
      })}
    >
      <div className={style.formContainer}>{children}</div>
      {shouldShowSuggestions && suggestions && (
        <div className={style.suggestions} aria-hidden="true">
          <div className={style.suggestionList} role="listbox">
            {suggestions.items.map(renderItem)}
          </div>
          {suggestions.footer && (
            <Footer
              onSuggestionsFooterClick={onSuggestionsFooterClick}
              footerText={getFooterText()}
            />
          )}
        </div>
      )}
    </div>
  );
};
