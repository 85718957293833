import {
  SearchSuggestionGroup,
  SearchSuggestionItem,
} from '@wix/thunderbolt-components';

import { ISearchBoxProps } from '../../../SearchBox.types';
import { noop } from '../../common';
import { suggestionItemTestIdPrefix } from '../../constants';
import { SearchSuggestions } from '../Suggestion.types';

export function buildSuggestions(
  props: ISearchBoxProps,
  activeItemId: number,
): SearchSuggestions {
  const {
    suggestions,
    onSuggestionGroupSelect = noop,
    onSuggestionSelect = noop,
    translate,
  } = props;
  if (!suggestions?.items) {
    return { ...suggestions, items: [] };
  }
  return {
    ...suggestions,
    items: suggestions.items.map(
      (item: SearchSuggestionItem | SearchSuggestionGroup, index) => {
        const type = item.type;
        const actionHandler =
          type === 'group' ? onSuggestionGroupSelect : onSuggestionSelect;
        return {
          action: () => actionHandler({ syntheticEvent: item }),
          id: `${suggestionItemTestIdPrefix}${index}`,
          isActive: index === activeItemId,
          item,
          translate: translate!,
          type,
        };
      },
    ),
  };
}
