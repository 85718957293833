import * as React from 'react';

import * as translations from '../../i18n.constants';
import { SuggestionGroupProps } from '../Suggestion.types';

import style from './Group.scss';

export const Group: React.FC<SuggestionGroupProps> = ({
  action,
  item,
  translate,
}) => {
  const groupLinkText = translate(
    translations.NAMESPACE,
    translations.SUGGESTION_GROUP_SHOW_ALL_KEY,
    translations.SUGGESTION_GROUP_SHOW_ALL_DEFAULT,
  );

  return (
    <div className={style.root}>
      <div className={style.inner}>
        <span>{item.title}</span>
        <a
          className={style.showAllLink}
          href={item.url}
          onClick={() => action()}
          title={`${groupLinkText} ${item.title}`}
        >
          {groupLinkText}
        </a>
      </div>
      <div className={style.divider} />
    </div>
  );
};
