import * as React from 'react';

import { ISearchBoxProps } from '../../../SearchBox.types';
import { buildSuggestions } from '../buildSuggestions';

export function useSuggestionNavigation(props: ISearchBoxProps) {
  const [activeOptionId, setActiveOptionId] = React.useState(-1);
  const scrollIntoRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (scrollIntoRef.current) {
      scrollIntoRef.current.scrollIntoView?.(false);
    }
  }, [activeOptionId]);

  const suggestionsWithNavigation = buildSuggestions(props, activeOptionId);

  const suggestionLastItemId = props.suggestions?.items?.length || 0;

  const moveUp = () => {
    setActiveOptionId(
      activeOptionId > 0 ? activeOptionId - 1 : suggestionLastItemId - 1,
    );
  };

  const moveDown = () => {
    setActiveOptionId(
      activeOptionId < suggestionLastItemId - 1 ? activeOptionId + 1 : 0,
    );
  };

  const navigate = () => {
    try {
      suggestionsWithNavigation?.items?.[activeOptionId].action();
    } catch (ex) {
      console.error('Error while navigating', ex);
    }
  };

  return {
    moveDown,
    moveUp,
    navigate,
    scrollIntoRef,
    activeOptionId,
    suggestionsWithNavigation,
  };
}
