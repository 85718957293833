export const NAMESPACE = 'Site_Search';

export const CLEAR_BUTTON_LABEL_KEY = 'search_box_clear_label';
export const CLEAR_BUTTON_LABEL_DEFAULT = 'Clear search';

export const SUGGESTION_GROUP_SHOW_ALL_KEY = 'search_box_suggestions_show_all';
export const SUGGESTION_GROUP_SHOW_ALL_DEFAULT = 'Show all';

export const SUGGESTION_FOOTER_SEARCH_ALL_KEY = 'search_box_search_all';
export const SUGGESTION_FOOTER_SEARCH_ALL_DEFAULT = 'Search all';
