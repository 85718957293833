import * as React from 'react';
import classNames from 'classnames';

import {
  ISearchBoxProps,
  ISearchBoxImperativeActions,
} from '../SearchBox.types';
import { ITextInputImperativeActions } from '../../TextInput/TextInput.types';
import { keyCodes } from '../../../core/commons/a11y';
import { Suggestions } from './suggestionsComponent/Suggestions';
import * as translations from './i18n.constants';
import { SearchBoxLayout } from './searchBoxLayout/SearchBoxLayout';
import { noop } from './common';
import { useSuggestionNavigation } from './suggestionsComponent/useSuggestionNavigation';

import style from './style/SearchBox.scss';

const SearchBox: React.ForwardRefRenderFunction<
  ISearchBoxImperativeActions,
  ISearchBoxProps
> = (props, ref) => {
  const {
    id,
    onSubmit = noop,
    placeholder = '',
    suggestions,
    translate,
    value = '',
    onKeyPress = noop,
    onFocus = noop,
    onBlur = noop,
    onChange = noop,
    onClick = noop,
    onDblClick = noop,
    onClear = noop,
    isDisabled = false,
    isSuggestionsOpen = false,
    suggestionsEnabled = false,
    onSuggestionsFooterClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;

  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    };
  });

  const inputRef = React.useRef<ITextInputImperativeActions>(null);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent,
  ) => {
    e.preventDefault();
    inputRef.current?.blur();
    onSubmit(e);
  };

  const clearButtonLabel = translate!(
    translations.NAMESPACE,
    translations.CLEAR_BUTTON_LABEL_KEY,
    translations.CLEAR_BUTTON_LABEL_DEFAULT,
  );

  const shouldShowSuggestions = Boolean(
    isSuggestionsOpen && suggestionsEnabled && suggestions,
  );

  const {
    activeOptionId,
    moveDown,
    moveUp,
    navigate,
    scrollIntoRef,
    suggestionsWithNavigation,
  } = useSuggestionNavigation(props);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === keyCodes.escape) {
      onClear();
    }

    if (shouldShowSuggestions) {
      const handler = ({
        [keyCodes.arrowUp]: moveUp,
        [keyCodes.arrowDown]: moveDown,
        [keyCodes.enter]: activeOptionId !== -1 ? navigate : undefined,
      } as { [key: number]: () => void })[e.keyCode];

      if (handler) {
        handler();
        e.preventDefault();
      }
    }
  };

  React.useEffect(() => {
    const componentHeight = parentRef?.current?.clientHeight;
    parentRef?.current?.style.setProperty(
      '--component-height',
      `${componentHeight}px`,
    );
  }, []);

  return (
    <div
      id={id}
      ref={parentRef}
      className={classNames(style.root, {
        [style.focused]: shouldShowSuggestions,
        'search-box-component-focus-ring-visible': isInputFocused,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Suggestions
        onSuggestionsFooterClick={onSuggestionsFooterClick}
        query={value}
        scrollIntoRef={scrollIntoRef}
        shouldShowSuggestions={shouldShowSuggestions}
        suggestions={suggestionsWithNavigation}
        translate={translate!}
      >
        <form
          className={classNames(
            style.container,
            shouldShowSuggestions && style.expanded,
          )}
          data-testid="search-box-form"
          onSubmit={handleSubmit}
          role="search"
          action="#"
          onClick={() => inputRef.current?.focus()}
          onKeyDown={handleKeyDown}
        >
          <SearchBoxLayout
            clearButtonLabel={clearButtonLabel}
            disabled={isDisabled}
            id={id}
            inputRef={inputRef}
            onBlur={e => {
              onBlur(e);
              setIsInputFocused(false);
            }}
            onClear={onClear}
            onClick={onClick}
            onDblClick={onDblClick}
            onFocus={e => {
              onFocus(e);
              setIsInputFocused(true);
            }}
            onInput={onChange}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            searchButtonLabel={placeholder}
            value={value}
          />
        </form>
      </Suggestions>
    </div>
  );
};

export default React.forwardRef(SearchBox);
