import * as React from 'react';

import { ReactComponent as CloseIcon } from './assets/closeIcon.svg';

import style from './ClearButton.scss';

export type ClearButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ClearButton: React.FunctionComponent<ClearButtonProps> = ({
  onClick,
  'aria-label': clearButtonLabel,
}) => {
  return (
    <span
      aria-label={clearButtonLabel}
      role="button"
      tabIndex={0}
      className={style.container}
      data-testid="clear-button"
      onClick={onClick}
    >
      <CloseIcon width="8px" height="8px" />
    </span>
  );
};
