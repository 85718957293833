import * as React from 'react';
import classNames from 'classnames';

import TextInput from '../../../../../TextInput/viewer/TextInput';
import { ClearButton } from '../clearButton';

import { ISearchInputPropsSDKActions } from '../../../../SearchBox.types';
import { ITextInputImperativeActions } from '../../../../../TextInput/TextInput.types';
import style from './SearchInput.scss';

type ISearchInputProps = ISearchInputPropsSDKActions & {
  clearButtonLabel: string;
  disabled: boolean;
  id: string;
  inputRef: React.MutableRefObject<ITextInputImperativeActions | null>;
  placeholder: string;
  value: string;
};

export const SearchInput: React.FunctionComponent<ISearchInputProps> = ({
  clearButtonLabel,
  disabled,
  id,
  inputRef,
  onBlur,
  onClear,
  onClick,
  onDblClick,
  onFocus,
  onInput,
  onKeyPress,
  placeholder,
  value,
}) => {
  const shouldDisplayClearButton = Boolean(value);

  return (
    <div
      className={classNames(
        style.container,
        shouldDisplayClearButton && style.withClearButton,
      )}
    >
      <div className={style.inputContainer}>
        <TextInput
          aria-label={placeholder}
          autoComplete_="off"
          autoComplete={false}
          id={`search-box-input-${id}`}
          inputType="search"
          isDisabled={disabled}
          maxLength={100}
          onBlur={onBlur}
          onClick={onClick}
          onDblClick={onDblClick}
          onFocus={onFocus}
          onInput={onInput}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          readOnly={disabled}
          ref={inputRef}
          required={false}
          shouldShowValidityIndication={false}
          skin="ResponsiveTextInputSkin"
          validateValueAndShowIndication={noop}
          value={value}
        />
      </div>
      {shouldDisplayClearButton && (
        <ClearButton onClick={onClear} aria-label={clearButtonLabel} />
      )}
    </div>
  );
};

function noop() {}
