import { IComponentController } from '@wix/editor-elements-types';

type ISearchBoxControllerActions = {
  closeSuggestions: () => void;
  onChange: (e: { target: HTMLInputElement }) => void;
  onClear: () => void;
};

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ISearchBoxControllerActions => ({
  closeSuggestions: () => {
    updateProps({ isSuggestionsOpen: false });
  },
  onChange: event => {
    updateProps({
      value: event.target.value,
    });
  },
  onClear: () => {
    updateProps({
      value: '',
      suggestions: null,
    });
  },
});

export default mapActionsToProps;
