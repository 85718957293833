import React, { useState } from 'react';
import classnames from 'classnames';

import Image from '../../../../Image/viewer/Image';
import { SuggestionItemProps } from '../Suggestion.types';
import style from './Item.scss';

type ThumbnailStates = 'loader' | 'fallBack' | 'image';

export const Item: React.FC<SuggestionItemProps> = ({ item, id, action }) => {
  const [thumbnailState, setThumbnailState] = useState<ThumbnailStates>(
    item.image?.name ? 'loader' : 'fallBack',
  );

  const onError = () => setThumbnailState('fallBack');
  const onLoad = () => setThumbnailState('image');

  return (
    <div onClick={() => action()}>
      <div className={style.root} title={item.title}>
        <div
          className={classnames(style.thumbnail, {
            [style.empty]: thumbnailState === 'fallBack',
            [style.loader]: thumbnailState === 'loader',
          })}
        >
          {thumbnailState !== 'fallBack' && (
            <Image
              alt={item.title}
              containerId={id}
              displayMode="fill"
              height={55}
              id={`thumb-${id}`}
              name={item.title}
              uri={item.image?.name}
              width={73}
              imageStyles={{
                objectFit: 'cover',
              }}
              onError={onError}
              onLoad={onLoad}
            />
          )}
        </div>
        <div className={style.content}>
          <span className={style.title}>{item.title}</span>
          {item.description && (
            <p
              className={style.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
