import * as React from 'react';
import classnames from 'classnames';
import { ITextInputImperativeActions } from '../../../TextInput/TextInput.types';
import { ISearchInputPropsSDKActions } from '../../SearchBox.types';
import { HAS_CUSTOM_FOCUS_CLASSNAME } from '../../../../core/commons/a11y';
import { SearchInput } from './components/searchInput';

import style from './SearchBoxLayout.scss';
import { SearchSymbol } from './components/searchSymbol';

type SearchInputLayoutProps = ISearchInputPropsSDKActions & {
  clearButtonLabel: string;
  disabled: boolean;
  id: string;
  inputRef: React.MutableRefObject<ITextInputImperativeActions | null>;
  placeholder: string;
  searchButtonLabel: string;
  value: string;
};

export const SearchBoxLayout: React.FunctionComponent<SearchInputLayoutProps> = ({
  clearButtonLabel,
  disabled,
  id,
  inputRef,
  onBlur,
  onClear,
  onClick,
  onDblClick,
  onFocus,
  onInput,
  onKeyPress,
  placeholder,
  searchButtonLabel,
  value,
}) => {
  return (
    <div className={style.container}>
      <div className={style.iconSpacer} />
      <div className={style.icon} data-testid="search-icon">
        <SearchSymbol />
      </div>
      <SearchInput
        clearButtonLabel={clearButtonLabel}
        disabled={disabled}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        onClear={onClear}
        onClick={onClick}
        onDblClick={onDblClick}
        onFocus={onFocus}
        onInput={onInput}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        value={value}
      />
      <button
        aria-label={searchButtonLabel}
        className={classnames(style.button, HAS_CUSTOM_FOCUS_CLASSNAME)}
        type="submit"
      >
        <SearchSymbol />
      </button>
    </div>
  );
};
